<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Whatsapp Integrations</h3>
      </div>
      <el-row :gutter="12" v-if="waAccounts.length">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="table-container"
        >
          <br />
          <div>
            <el-button
              type="primary"
              @click="newIntegration"
              style="float: right; margin: 1rem"
              >Add</el-button
            >
          </div>
          <br />
          <template>
            <el-table
              border
              style="margin: auto; width: 95%; position: relative; bottom: 10px;"
              :data="waAccounts"
            >
              <el-table-column prop="name" label="Account Name" />
              <el-table-column prop="contactNo" label="WA AC Number" />
              <el-table-column prop="type" label="Type" />
              <el-table-column label="Status" align="center">
                <template slot-scope="scope">
                  <i
                    v-if="scope.row.status === 'ACTIVE'"
                    class="fa fa-check"
                    style="color: green"
                  ></i>
                  <i v-else class="fa fa-times" style="color: red"></i>
                </template>
              </el-table-column>
              <el-table-column label="Actions" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="small"
                    @click="deleteConnection(scope.row)"
                  >
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination-sty">
            <center>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                :pager-count="5"
                :total="total"
                layout="prev, pager, next, jumper,sizes, total"
                small
              >
              </el-pagination>
            </center>
          </div>
          </template>
        </el-col>
      </el-row>
      <el-row :gutter="12" v-else>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="table-container mt-20 ml-3 mb-10"
        >
          <div class="no-data">
            <img src="@/assets/img/nodata2.svg" alt="No Data" />
            <p>
              There are no integrations currently available. Click 'Add New
              Integration' to set up an integration.
            </p>
            <div>
              <el-button type="primary" @click="newIntegration"
                >Add New Integration</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { logOutInstance } from "./whatsapp";
import { postAPICall } from "@/helpers/httpHelper";
export default {
  name: "AddNewIntegration",
  data() {
    return {
      loading: false,
      loadingText: "Loading .....",
      waAccounts: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      pageSizes: [10, 20, 50],
    };
  },
  async mounted() {
    this.getWAAccounts();
  },
  methods: {
    newIntegration() {
      this.$router.push({ path: "/configuration/Whatsapp" });
    },
    async deleteConnection(connection) {
      this.$confirm("Are you sure to delete?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          this.loadingText = "Deleting WhatsApp Account ...";
          const response = await logOutInstance(connection.instanceId);

          if (
            !response.error ||
            response.message[0].endsWith(
              "instance does not exist or is not connected"
            )
          ) {
            const deleteResponse = await postAPICall(
              "DELETE",
              "/wa/delete-WA-integration-data",
              { instanceId: connection.instanceId }
            );

            if (deleteResponse.success) {
              this.waAccounts = this.waAccounts.filter(
                (account) => account.instanceId !== connection.instanceId
              );
              this.$notify({
                type: "success",
                message: "WhatsApp account deleted successfully.",
              });
            } else {
              this.$notify({
                type: "error",
                message: "Failed to delete the WhatsApp account.",
              });
            }
          } else {
            this.$notify({
              type: "error",
              message: "Failed to log out the WhatsApp instance.",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$notify({
            type: "info",
            message: "Deletion canceled.",
          });
        });
    },
    async getWAAccounts() {
      this.loading = true;
      this.loadingText = "Loading Whatsapp Accounts ...";
      try {
        let response = await postAPICall("GET", "/wa/get-WA-integration-data");
        
        if (response && response.success) {
          this.waAccounts = response.data;
          this.total = response.total || response.data.length; 
          }
        } catch (error) {
          console.error("Error fetching WA accounts:", error);
        } finally {
          this.loading = false;
        }
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.pagination-sty{
  position: relative;
  bottom: 8px !important;
}
</style>
